import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import { config } from "../config";

class ExtraHoursService {
  getForApprovalToApprove(queryStringParametersObj) {
    const params = new URLSearchParams(queryStringParametersObj);
    return axios
      .get(`${config["apiUrl"]}extraHours/forApproval/toApprove?${params}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  approve(id, $bodyParms) {
    return axios
      .patch(`${config["apiUrl"]}extraHours/approve/${id}`, $bodyParms, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getTechnicalExtraHours(technical, queryStringParametersObj) {
    const params = new URLSearchParams(queryStringParametersObj);
    return axios
      .get(`${config["apiUrl"]}extraHours/technical/${technical}?${params}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  coutTechnicalExtraHours(technical, queryStringParametersObj) {
    const params = new URLSearchParams(queryStringParametersObj);
    return axios
      .get(`${config["apiUrl"]}extraHours/technical/${technical}/count?${params}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
}
export default new ExtraHoursService();
