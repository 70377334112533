<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '50vw' }"
      :header="`Approvar Horas Extras`"
      :modal="true"
      :closable="false"
    >
      <form
        @submit.prevent="handleSubmit"
        style="min-height: 15vh"
        class="p-mt-3"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-3">
            <span class="p-float-label">
              <InputText
                name="technical"
                :disabled="true"
                v-model="data.technical.username"
                v-bind:class="['form-control']"
              />
              <label for="technical">Técnico</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-3">
            <span class="p-float-label">
              <InputText
                name="day"
                :disabled="true"
                v-model="data.date"
                v-bind:class="['form-control']"
              />
              <label for="day">Dia</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-3">
            <span class="p-float-label">
              <InputText
                name="start"
                :disabled="true"
                v-model="data.start"
                v-bind:class="['form-control']"
              />
              <label for="start">Hora de Inicio</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-3">
            <span class="p-float-label">
              <InputText
                name="end"
                :disabled="true"
                v-model="data.end"
                v-bind:class="['form-control']"
              />
              <label for="end">Hora de Fim</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-3">
            <span class="p-float-label">
              <InputText
                name="interventionInfo"
                :disabled="true"
                v-model="data.interventionInfo"
                v-bind:class="['form-control']"
              />
              <label for="interventionInfo">Pat Info</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-3">
            <span class="p-float-label">
              <Textarea
                type="text"
                name="resume"
                :autoResize="true"
                rows="1"
                v-model="data.intervention.resume"
                :disabled="true"
              />
              <label for="resume">Trabalho Efetuado</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-3">
            <span class="p-float-label">
              <Textarea
                type="text"
                name="notes"
                :autoResize="true"
                rows="1"
                v-model="data.intervention.notes"
                :disabled="true"
              />
              <label for="notes">Notas</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-4 p-mt-3">
            <span class="p-float-label">
              <InputText
                name="extraHours"
                :disabled="true"
                v-model="data.extra_hours"
                v-bind:class="['form-control']"
              />
              <label for="extraHours">Horas Extras</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <span class="p-float-label">
              <InputText
                name="type"
                :disabled="true"
                v-model="data.type"
                v-bind:class="['form-control']"
              />
              <label for="type">Tipo de Horas Extras</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-2 p-mt-3">
            <span
              class="p-float-label"
              :style="{ top: '-0.75rem', left: '-0.5rem', fontSize: '12px' }"
            >
              <label for="aproved">Aprovada</label>
            </span>
            <InputSwitch
              v-model="data.aproved"
              :name="'aproved'"
              style="'top: 3px;"
              :disabled="!inApprove"
              @change="aprovedChange()"
            />
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-3">
            <span class="p-float-label">
              <Textarea
                type="text"
                name="reason_rejection"
                :autoResize="true"
                rows="3"
                :disabled="!inApprove || data.aproved === true"
                v-validate="inApprove === true ? 'required' : ''"
                v-model="data.reason_rejection"
                v-bind:class="[
                  { 'p-invalid': errors.has('reason_rejection') },
                  'form-control',
                ]"
              />
              <label for="notes">Razão da Rejeição</label>
            </span>
            <small
              v-if="errors.has('reason_rejection')"
              class="p-error"
              role="alert"
            >
              Razão da Rejeição é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          v-if="inApprove"
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="handleSubmit"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import extraHoursService from "../../services/extraHours.service";

export default {
  name: "FormApproveExtraHours",
  props: ["show", "info", "inApprove"],
  watch: {
    show() {
      if (this.show == true) {
        this.$validator.resume();
        this.data = this.info;
      } else {
        this.$validator.pause();
        this.resetData();
      }
    },
  },
  data() {
    return {
      data: {
        intervention: {},
        technical: {},
      },
    };
  },
  methods: {
    resetData() {
      return (this.data = {
        intervention: {},
        technical: {},
      });
    },
    aprovedChange() {
      if (this.data.aproved === true) {
        this.data.reason_rejection = "";
      }
    },
    handleSubmit() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        let params = {
          approve: this.data.aproved === true ? 1 : 0,
        };
        if (this.data.aproved === false) {
          params.reason_rejection = this.data.reason_rejection;
        }
        extraHoursService.approve(this.data.id, params).then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro Approvar Hora Extra",
              detail: "",
              life: 3000,
            });
          }
          this.$emit("extraHourApproved");
          this.$toast.add({
            severity: "success",
            summary: `Hora Extra aprovada`,
            detail: ``,
            life: 3000,
          });
          return this.resetData();
        });
      });
    },
    cancel() {
      this.resetData;
      return this.$emit("cancel");
    },
  },
};
</script>

<style scoped>
.extra-hours-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
</style>
