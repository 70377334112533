import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import { config } from "../config";

class Paginator {
  getItems(url) {
    if (url.includes("https://intranet.w4msolutions.com")) {
      url = url.replace("https://intranet.w4msolutions.com", config["apiUrl"]);
    }
    return axios
      .get(`${url}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getItemsWithFilters(url, filters) {
    if (url.includes("https://intranet.w4msolutions.com")) {
      url = url.replace("https://intranet.w4msolutions.com", config["apiUrl"]);
    }
    const params = new URLSearchParams(filters);
    return axios
      .get(`${url}&${params}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
}
export default new Paginator();
